import { Injectable } from '@angular/core';
import { config } from '@tag/graphql';
import type { CrewSchedule } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';

interface Response {
  crewSchedule: { items: CrewSchedule[] };
}

@Injectable({
  providedIn: 'root',
})
export class CrewScheduleGQL extends Query<Response> {
  document = gql(config.crewSchedule.query);
}
