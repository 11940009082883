import { Injectable } from '@angular/core';
import { config } from '@tag/graphql';
import type { Crew } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';

interface Response {
  crews: { items: Crew[] };
}

@Injectable({
  providedIn: 'root',
})
export class CrewsGQL extends Query<Response> {
  document = gql(config.crew.query);
}
